<template>
  <!-- router-link 最终渲染成a标签, to属性的值是 路由地址( 不是文件路径 ) , 这个路由地址必须提前在路由表中定义 -->
  <footer>
    <van-tabbar route>
      <van-tabbar-item replace to="/Index/Shop" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item replace to="/Index/Ordex" icon="search">爆爆团</van-tabbar-item>
      <van-tabbar-item replace to="/Index/List" icon="friends-o">订单</van-tabbar-item>
      <van-tabbar-item replace to="/Index/Mines" icon="setting-o">我的</van-tabbar-item>
    </van-tabbar>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      active:0,
    };
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
footer {
  width: 100%;
  height: 80px;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #ccc;
  background-color: white;
}

</style>