import Vue from 'vue'
//1.引入路由插件
import VueRouter from 'vue-router'

//2.注册路由插件
Vue.use(VueRouter)

//路由懒加载( 缓解首页加载白屏时间过长的问题 )
//一旦写成 路由懒加载的形式, 实际跳转路由地址 才会动态加载对应的组件
/* () => import('../views/AboutView.vue') */
const routes = [
  {
    path: '/Index', //定义路由地址
    name: 'Index',//定义路由名字
    component: () => import('../views/Index.vue'), //定义路由地址匹配的组件
    children: [
      {
        path: '/Index/Ordex',
        name: 'Ordex',
        meta:{title:"Ordex"},
        component: () => import('../views/Index/Ordex.vue'),
        children: [
          {
            path: '/Index/Ordex/Hot',
            name: 'Hot Games',
            meta:{title:"Fun Games Hub"},
            component: () => import('../views/Index/Hot.vue'),
          },
          {
            path: '/Index/Ordex/NavBar',
            name: 'NavBar',
            meta:{title:"Game Navigation"},
            component: () => import('../views/Index/NavBar.vue'),
          },
          {
            path: '/Index/Ordex/NarSearch',
            name: 'NarSearch',
            meta:{title:"Game Explorer"},
            component: () => import('../views/Index/Search.vue'),
          },
          {
            path: '/Index/Ordex/Mini',
            name: 'Mini Games',
            meta:{title:"Ultimate Online Gaming Hub"},
            component: () => import('../views/Index/Mini.vue'),
          },
          {
            path: '/Index/Ordex/RiskType',
            name: 'RiskType Games',
            meta:{title:"Play most of the HTML5 "},
            component: () => import('../views/Index/RiskType.vue'),
          },
          {
            path: '/Index/Ordex/PlayStream',
            name: 'PlayStream',
            meta:{title:"Play Free HTML5 Games Online"},
            component: () => import('../views/Index/PlayStream.vue'),
          },
          {
            path: '/Index/Ordex/Casual',
            name: 'Casual Games',
            meta:{title:"Games on various devices"},
            component: () => import('../views/Index/Casual.vue'),
          },
          {
            path: '/Index/Ordex/Latest',
            name: 'Hot Games',
            meta:{title:"Play for free online"},
            component: () => import('../views/Index/Latest.vue'),
          },
          {
            path: '/Index/Ordex/GameDetail',
            name: 'GameDetail',
            meta:{title:"Play games freely"},
            component: () => import('../views/Index/GameDetail.vue'),
          },
          {
            path: '/Index/Ordex/GameDetail',
            // path: '/Detail/:id',
            name: 'Detail',
            meta:{title:"Play games freely"},
            component: () => import('../views/Index/GameDetail.vue')
          },
          {
            path: '/Index/Ordex/PrivacyPolicy',
            // path: '/Detail/:id',
            name: 'Privacy Policy',
            meta:{title:"Privacy Policy"},
            component: () => import('../views/Index/PrivacyPolicy.vue')
          },
          {
            path: '/Index/Ordex/TermsService',
            // path: '/Detail/:id',
            name: 'Terms of Service',
            meta:{title:"Terms of Service"},
            component: () => import('../views/Index/TermsService.vue')
          },
          {
            path: '/Index/Ordex/Search',
            name: 'Search',
            meta:{title:"Game Explorer"},
            component: () => import('../views/Index/searchList.vue'),
          },
          { //一级路由的 重定向规则
            path: '/Index/Ordex',
            redirect: '/Index/Ordex/PlayStream'
          },
        ]
      },
      { //二级路由的 重定向规则
        path: '/Index',
        redirect: '/Index/Ordex/PlayStream'
      },
      { //二级路由的 404路由规则
        path:'*',
        meta:{title:'404'},
        component: () => import('../views/NotFound.vue'), 
      }
    ],

  },
  {
    path: '/Game', //定义路由地址
    name: 'Game',
    meta:{title:'Game'},
    component: () => import('../views/Game.vue')

  },
  { //一级路由的 重定向规则
    path: '/',
    redirect: 'Index/Ordex/PlayStream'
  },
  { //一级路由 404 路由规则 , * 表 匹配所有路由  ( 只有路由地址和前面的所有路由地址都不匹配时 这里的 * 才会生效 )
    path:'*',
    meta:{title:'404'},
    component: () => import('../views/NotFound.vue'), 
  }
]

//3.实例化路由插件
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title=to.meta.title
  next();
  // if (to.path == '/Index/List'||to.path == '/Index/Mines') {
  //   if (localStorage.getItem('token')) {
  //     next()
  //    }else{
  //     next('/Login');
  //    }
  // }else{
  //   next();
  // }
})


export default router
