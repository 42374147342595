import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MyTabbar from './components/Tabbar'
import font from './assets/font/iconfont.css'
import store from '@/store/index.js'; // 引入 Vuex store
// import LazyLoad from 'vue-lazyload';
// Vue.use(LazyLoad, {
//   preLoad: 1.3,
//   loading: './assets/zw.webp',
//   error: './assets/zw.webp',
//   attempt: 1
// });


import { Search,Swipe, SwipeItem,Lazyload,Tabbar, TabbarItem,NavBar,Dialog,Form,Field,Toast,Button,Icon,Overlay,Divider} from 'vant';
import { Image as VanImage } from 'vant';



// 全局注册
Vue.use(Dialog);
Vue.use(Toast);



Vue.use(NavBar);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Search);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Overlay);
Vue.use(Divider);
Vue.use(VanImage);

Vue.config.productionTip = false

Vue.component('MyTabbar',MyTabbar);

new Vue({
  router, //4.挂载到根实例上
  store,
  render: h => h(App)
}).$mount('#app')
