//统一管理 应用程序中的接口
//一个接口封装成一个函数
import axios from "./request";

// /game/list 游戏列表查询
function getGamesList(params={}){
    return axios.post('/api/game/list',params)
}


// /game/{game_id} 游戏详情
function getGamesDetails(game_id){
    return axios.get(`/api/game/${game_id}`)
}
// /game/play-count/{game_id} 统计游戏play次数
function getGamesPlayCount(game_id){
    return axios.post(`/api/game/play-count/${game_id}`)
}
// /game/search  游戏搜索
function getGameSearch(keywords){
    return axios.get(`/api/game/search?keywords=${keywords}`)
}

// /game/recommend/list 推荐游戏列表查询
function getRecommendList(params={}){
    return axios.post('/api/game/recommend/list',params)
}

// /game/new/list  New Game列表查询
function getNewList(){
    return axios.post('/api/game/new/list')
}

// /game/best/list Best Game列表查询
function getBestList(){
    return axios.post('/api/game/best/list')
}
// /category/list 游戏类目列表查询
function getCategoryList(){
    return axios.post('/api/category/list')
}
export{
    getGamesList,
    getGamesDetails,
    getGamesPlayCount,
    getGameSearch,
    getRecommendList,
    getNewList,
    getBestList,
    getCategoryList
}