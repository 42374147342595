//对axios进行封装(设置拦截器)
import axios from "axios";
import { Toast } from "vant"; 
import router from "../router";



//设置请求拦截器
axios.interceptors.request.use((config) => {//ajax请求从浏览器发出前自动执行
    //给ajax请求 添加请求头( token )
    // config.headers['Authorization'] = "Bearer" + localStorage.getItem('token');
    return config;
}, (error) => {//ajax请求报错时执行
    return Promise.reject(error);
})

//设置响应拦截器
axios.interceptors.response.use((res) => {//响应从服务器返回给浏览器时 自动执行
    return res;
}, (error) => {//响应从服务器返回, 但是 报错了
    //判断错误码, 给用户相应提示
    // if (error.response.status == 401) {//登陆过期(身份验证失败)
    //     Toast.fail("登录过期(身份验证失败)");
    //     //跳转到登录页
    //     router.push('/login');
    //     //删除本地存储中的token
    //     localStorage.removeItem('token')
    // } else if (error.response.status == 404) {//访问路径有误( 访问不到该资源 )
    //     Toast.fail("访问路径有误( 访问不到该资源 )")
    // } else if (error.response.status == 500) {//服务器内部错误
    //     Toast.fail("服务器内部错误")
    // }
        // 根据错误码给用户提示
        if (error.response.status === 404) {
            Toast.fail('访问路径有误( 访问不到该资源 )');
          } else if (error.response.status === 500) {
            Toast.fail('服务器内部错误');
          }
    return Promise.reject(error);
})


export default axios;