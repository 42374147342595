import vue from 'vue';
import Vuex from 'vuex';
import { getGameSearch } from "@/request/api.js";


vue .use(Vuex);

var store = new Vuex.Store({
  state: {
    searchResults: [],
    searchValue: '',
  },
  mutations: {
    setSearchValue(state, value) {
        state.searchValue = value;
        console.log(state.searchValue, '搜索内容');
      },
      setResults(state, results) {
        state.searchResults = results;
      }
  },
  actions: {
      fetchSearchResults({ commit }, query) {
        // this.state.searchValue = query;
        commit('setSearchValue', query);
        console.log(this.state.searchValue,"搜索内容");
        // 这里调用你的 API 接口
        // getGameSearch.then(({ getGameSearch }) => {
            getGameSearch(query).then((response) => {
              const data = response.data.data;
              commit('setResults', data);
              console.log(data,999);
            }).catch((error) => {
              console.error(error);
            });
        // });
      }
  },
  getters: {
    searchValue: state => state.searchValue,
    searchResults: state => state.searchResults,
  }
})

export default store;